import Vue from 'vue'
import VueRouter from 'vue-router'
import * as dd from 'dingtalk-jsapi'
import { dingLogin, getUserInfoUrl } from "@/api/admin";

Vue.use(VueRouter)


const asyncRouterMap = [
  {
    path: '/TechSetByOhters',
    name: 'TechSetByOhters',
    meta: { title: '工单填写' },
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/TechSetByOhters/index.vue')
  },
  {
    path: '/cusSetPhone',
    name: 'cusSetPhone',
    meta: { title: '登录页面' },
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/cusSetPhone/index.vue')
  },
  {
    path: '/cusSet',
    name: 'cusSet',
    meta: { title: '登录页面' },
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/cusSet/index.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    meta: { title: '登录页面' },
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Login/index.vue')
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/layout/index.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'adminMain',
        meta: { title: '首页' },
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/MainView/index.vue')
      },
      {
        path: '/ESOrderReportManager',
        name: 'ESOrderReportManager',
        meta: { title: '电商工单' },
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/ESOrderReportManager/index.vue')
      },

      {
        path: '/OwtoecOrder',
        name: 'OwtoecOrder',
        meta: { title: '官网转电商工单' },
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/OwtoecOrder/index.vue')
      },

      {
        path: '/CustomerReportManager',
        name: 'CustomerReportManager',
        meta: { title: '客服工单' },
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/CustomerReportManager/index.vue')
      },
      {
        path: '/FAEReportManager',
        name: 'FAEReportManager',
        meta: { title: 'FAE工单' },
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/FAEReportManager/index.vue')
      },
      {
        path: '/TechReportManager',
        name: 'TechReportManager',
        meta: { title: '研发工单' },
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/TechReportManager/index.vue')
      },


      //工单填写
      {
        path: 'FAEOrderset',
        name: 'FAEOrderset',
        component: () => import('@/views/FAEOrderset/index'),
        meta: { title: 'Fae/业务' }
      },
      {
        path: 'ESOrderSet',
        name: 'ESOrderSet',
        component: () => import('@/views/ESOrderSet/index'),
        meta: { title: '海外电商' }
      },
      {
        path: 'CustomerOrderset',
        name: 'CustomerOrderset',
        component: () => import('@/views/CustomerOrderset/index'),
        meta: { title: '国内客服' }
      },
      {
        path: 'TechOrderSet',
        name: 'TechOrderSet',
        component: () => import('@/views/TechOrderSet/index'),
        meta: { title: '研发工站' }
      },

      {
        path: 'InternationalOrder',
        name: 'InternationalOrder',
        component: () => import('@/views/InternationalOrder/index'),
        meta: { title: '海外客户' }
      },

      {
        path: 'clientOrder',
        name: 'clientOrder',
        component: () => import('@/views/clientOrder/index'),
        meta: { title: '国内客户' }
      },

      {
        path: 'websiteOrder',
        name: 'websiteOrder',
        component: () => import('@/views/websiteOrder/index'),
        meta: { title: '官网工单' }
      },




      //角色管理

      {
        path: 'department',
        name: 'department',
        component: () => import('@/views/user/department/index'),
        meta: { title: '部门信息' }
      },
      {
        path: 'position',
        name: 'Position',
        component: () => import('@/views/user/position/index'),
        meta: { title: '职位信息' }
      },
      {
        path: 'role',
        name: 'Role',
        component: () => import('@/views/user/role/index'),
        meta: { title: '角色信息' }
      },
      {
        path: 'user-info',
        name: 'UserInfo',
        component: () => import('@/views/user/user/index'),
        meta: { title: '用户信息' }
      },
      {
        path: 'tobcustomer',
        name: 'tobcustomer',
        component: () => import('@/views/user/tobcustomer/index'),
        meta: { title: '海外客户' }
      },
      //其他
      {
        path: 'OpinionManage',
        name: 'OpinionManage',
        component: () => import('@/views/OpinionManage/index'),
        meta: { title: '客户意见单' }
      },
      {
        path: 'QualityOrderSet',
        name: 'QualityOrderSet',
        component: () => import('@/views/QualityOrderSet/index'),
        meta: { title: '客诉工单' }
      },
      {
        path: 'QuiltyReport',
        name: 'QuiltyReport',
        component: () => import('@/views/QuiltyReport/index'),
        meta: { title: '品质报告' }
      },


      {
        path: 'CustomerAssess',
        name: 'CustomerAssess',
        component: () => import('@/views/CustomerAssess/index'),
        meta: { title: '客户评价' }
      }
    ]
  },
]


const router = new VueRouter({
  routes: asyncRouterMap
})




/* 钉钉免登 */
router.beforeEach((to, from, next) => {
  if (dd.env.platform === 'notInDingTalk') {
    // if (process.env.NODE_ENV !== 'development') return next()
    return next()
  }

  try {
    // 进行钉钉登录操作
    dd.ready(() => {
      const corpId = to.query.corpid || ''

      dd.runtime.permission.requestAuthCode({
        corpId,
        onSuccess: async ({ code }) => {
          const formData = new FormData()
          formData.append("code", code)
          const res = await makeRequest(formData)
          dd.biz.util.openLink({ url: location.href + `&token=${res.data}` })

        },
        onFail: (err) => {
          console.error(err)
          next()
        }
      })
    })
  } catch (e) {
    next()
  }
})

const makeRequest = async (data) => {
  const value = await dingLogin(data)
  return value.data
}

const getUserInfo = async (data) => {
  const res = await getUserInfoUrl(data)
  window.localStorage.setItem(
    "userdata",
    JSON.stringify(res.data.data)
  );
  const limitdata = res.data.data.permInfos;
  const menulist = getlimit(limitdata);
  window.localStorage.setItem("menulist", JSON.stringify(menulist));
}

const getlimit = (limitdata) => {
  menulist: [
    {
      perms: "system",
      index: "/dashboard",
      icon: "el-icon-s-home",
      title: "首页",
      show: true,
    },
    {
      perms: "work_order",
      icon: "el-icon-s-data",
      title: "统计管理",
      show: true,
      children: [
        {
          perms: "ec-serve-client-work-order:manage",
          index: "/ESOrderReportManager",
          icon: "el-icon-menu",
          title: "电商工单",
          show: true,
        },
        {
          perms: "customer-work-order:manage",
          index: "/CustomerReportManager",
          icon: "el-icon-menu",
          title: "客服工单",
          show: true,
        },
        {
          perms: "fae-work-order:manage",
          index: "/FAEReportManager",
          icon: "el-icon-menu",
          title: "FAE工单",
          show: true,
        },
        {
          perms: "development-work-order:manage",
          index: "/TechReportManager",
          icon: "el-icon-menu",
          title: "研发工单",
          show: true,
        },
      ],
    },
    {
      perms: "work_order_manage",
      icon: "el-icon-s-operation",
      title: this.$t("gdgl"),
      show: true,
      children: [
        {
          perms: "customer-work-order",
          index: "/CustomerOrderset",
          icon: "el-icon-phone-outline",
          title: "国内客服",
          show: true,
        },
        {
          perms: "ec-serve-client-work-order",
          index: "/ESOrderSet",
          icon: "el-icon-sell",
          title: "海外电商",
          show: true,
        },

        {
          perms: "business-customer-work-order",
          index: "/InternationalOrder",
          icon: "el-icon-s-promotion",
          title: "海外客户",
          show: true,
        },

        {
          perms: "client-customer-work-order",
          index: "/clientOrder",
          icon: "el-icon-user",
          title: '国内客户',
          show: true,
        },

        {
          perms: "technical-support-work-order",
          index: "/FAEOrderset",
          icon: "el-icon-s-custom",
          title: this.$t("faeAndyw"),
          show: true,
        },

        
        {
          perms: "website-work-order",
          index: "/websiteOrder",
          icon: "el-icon-help",
          title: "官网工单",
          show: true,
        },

        {
          perms: "development-work-order",
          index: "/TechOrderSet",
          icon: "el-icon-cpu",
          title: "研发工站",
          show: true,
        },
      ],
    },
    {
      perms: "quality_management",
      icon: "el-icon-message-solid",
      title: "品质管理",
      show: true,
      children: [
        {
          perms: "customer-complaint-work-order",
          index: "/QualityOrderSet",
          icon: "el-icon-s-comment",
          title: "客诉工单",
          show: true,
        },
        {
          perms: "quality-report",
          index: "/QuiltyReport",
          icon: "el-icon-tickets",
          title: "品质报告",
          show: true,
        },
      ],
    },
    {
      perms: "opinion-work-order",
      icon: "el-icon-info",
      title: "意见管理",
      show: true,
      children: [
        {
          perms: "opinion-work-order:manage",
          index: "/OpinionManage",
          icon: "el-icon-s-comment",
          title: "客户意见单",
          show: true,
        },
      ],
    },

    {
      perms: "assessment_management",
      icon: "el-icon-chat-dot-round",
      title: "评价管理",
      show: true,
      children: [
        {
          perms: "customer-work-order-assess",
          index: "/CustomerAssess",
          icon: "el-icon-s-comment",
          title: "客户评价",
          show: true,
        },
      ],
    },

    {
      perms: "base-information",
      icon: "el-icon-setting",
      title: "系统管理 ",
      show: true,
      children: [
        {
          perms: "dept:manage",
          index: "/department",
          icon: "el-icon-more",
          title: "部门",
          show: true,
        },
        {
          perms: "post:manage",
          index: "/position",
          icon: "el-icon-s-custom",
          title: "职位",
          show: true,
        },
        {
          perms: "role:manage",
          index: "/role",
          icon: "el-icon-user",
          title: "角色",
          show: true,
        },
        {
          perms: "user:manage",
          index: "/user-info",
          icon: "el-icon-user-solid",
          title: "用户",
          show: true,
        },
      ],
    },
  ];
  for (let i = 0; i < menulist.length; i++) {
    for (let l = 0; l < limitdata.length; l++) {
      if (menulist[i].perms == limitdata[l].perms) {
        break;
      } else if (l == limitdata.length - 1) {
        menulist[i].show = false;
        break;
      }
    }

    if (menulist[i].children != null) {
      for (let j = 0; j < menulist[i].children.length; j++) {
        for (let k = 0; k < limitdata.length; k++) {
          if (
            menulist[i].children[j].perms == limitdata[k].perms
          ) {
            break;
          } else if (k == limitdata.length - 1) {
            menulist[i].children[j].show = false;
            break;
          }
        }
      }
    }
  }
  return menulist;
}



router.beforeEach((to, form, next) => {


  const hasToken = to.query.token

  if (!hasToken) {
    if (to.path == '/') {
      next('/dashboard')
    }

    return next()
  }
  else {
    window.localStorage.setItem(
      "usertoken",
      JSON.stringify(hasToken)
    );
    getUserInfo()

  }

  const user = JSON.parse(window.localStorage.getItem('usertoken'))
  if (to.path !== '/Login') {
    if (user) {
      if (to.path == '/') {
        next('/dashboard')
      } else { next() }

    } else {
      next('/Login')
    }
  }

  else {

    next()
  }
})



export default router

