import request from "../utils/requst"

export const login = data =>{
    return request({
        method:'post',
        url:'home/login',
        data,
    })
}

export const platformlogin = data =>{
    return request({
        method:'post',
        url:'home/platform/login',
        data,
    })
}

export const softwarelogin = data =>{
    return request({
        method:'post',
        url:'home/software/login',
        data,
    })
}

export const addpeople = data =>{
    return request({
        method:'POST',
        url:'sys/user/add',
        data,
    })
}
export const changePassWord = data =>{
    return request({
        method:'PUT',
        url:'sys/user/password/modify',
        data,
    })
}
export const getstaff = params =>{
    return request({
        method:'GET',
        url:'sys/user/page',
        params,
    })
}

export const delectstaff = params =>{
    return request({
        method:'DELETE',
        url:'sys/user/del',
        params,
    })
}

export const getservicelist = params =>{
    return request({
        method:'GET',
        url:'sys/user/cs/list',
        params,
    })
}

export const csloginUrl = data =>{
    return request({
        method:'POST',
        url:'home/cs/login',
        data,
    })
}

export const dingLogin = data =>{
    return request({
        method:'POST',
        url:'home/ding/login',
        data,
    })
}

export const getUserInfoUrl = params =>{
    return request({
        method:'GET',
        url:'sys/user/get',
        params,
    })
}

export const getPastMonthDataUrl = params =>{
    return request({
        method:'GET',
        url:'procedure/bulletin-board/pastMonthData',
        params,
    })
}

export const getchannelsDataUrl = params =>{
    return request({
        method:'GET',
        url:'procedure/bulletin-board/channelsData',
        params,
    })
}
export const gettypeDataUrl = params =>{
    return request({
        method:'GET',
        url:'procedure/bulletin-board/typeData',
        params,
    })
}

export const getfaeOperatorDataUrl = params =>{
    return request({
        method:'GET',
        url:'procedure/bulletin-board/faeOperatorData',
        params,
    })
}

export const gettopTenProductModelDataUrl = params =>{
    return request({
        method:'GET',
        url:'procedure/bulletin-board/topTenProductModelData',
        params,
    })
}

