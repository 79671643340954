export default {
  'Language': 'English',
  'checkText': "Please select",
  'inputText': "Please input",
  'quxiao': "CANCEL",
  'queding': "CONFIRM",
  'jincishengxiao': "Temporary settings", 
  'baocunshezhe': "Permanent settings",
  'sl_time': {
    'text': 'Please select a correct time period',
    'begin': "Select a start date",
    'over': "Select a end date"
  },
  'bt_clear': "CLEAR",
  'bt_search': "QUERY",
  'bt_new': "NEW WORK ORDER",
  'bt_senior_check': "ADVANCED FILTER",
  'bt_retrac': "RETRACT",
  'bt_check': "Content Query",
  'processes': "Work order progress",
  'workOrderNo': "Work order No.",
  'createdName': "Creator",
  'customerName': "Customer company name",
  'personLiable': "R&D handler",
  'faeOperatorName': "FAE handler",
  'salesman': "Salesman",
  'platform': "Platform",
  'stations': "Current site",
  'type': "Issue type",
  'urgentType': "Urgency level",
  'transformFlag': "Transfer state",
  'description': "Description",
  'productModelType': "Product",
  'selfFlag': "Source",
  'ownerTypeSelf': "Current account",
  'ownerTypeOthers': "All accounts",

  'totalCount_text_TOP': "According to the filtering criteria, a total of ",
  'totalCount_text_over': "queries were found",

  'process': "Issue status",
  'completedByName': "Accomplished by",
  'createdTime': "Create date",
  'station': "Current site",
  'area': "Sales Area",
  'keyword': "Keyword",
  'remark': "Remark",
  'customerDescription': "Customer's original words/Other",
  'operate': "Function buttons",

  "lookAndDo": "View details/work order processing",
  "chongqi": "Restart work order",
  "wancheng": "Accomplish work order",
  "baingengfae": "Change FAE handler",
  "huisuyanfa": "Recall R&D work order",
  "xiugaijichu": "Modify basic order info",
  "chakanshijian": "View timeline",
  "shanchu": "Delete work order",

  "shifoushanchu": "Do you want to delete this work order？",
  "quedingshanchu": "Confirm delete",
  "zanbushanchu": "Let me think",

  "shifouhuisu": "Are you sure to recall this R&D work order",
  "quedinghuisu": "Confirm recall",
  "zanbuhuisu": "Let me think",

  "khgn": "Client(Domestic)",
  "productType": "Product Type",
  "networkType": "Network Type",

  "di": "Low",
  "zhong": "Medium",
  "gao": "High",
  "jigao": "Extreme high",

  "tupian": "Image",
  "tupiantixing": "Select Image (JPG,. png only)",
  "wenjian": "File",
  "fujian": "Attachment",
  "dianjishangchuan": "Upload attachment files( document, video or logs file)",
  "shijianxianliebiao": "Timeline",
  "gongdanliucheng": "Work order progress",
  "tianjiagongdan": "Add Work Order",
  "tixing": "Reminder",
  "tixingxijie":
`Incoming material issues: There are inherent issues with the materials provided by the supplier. (such as power cord quality issues, microphone quality issues, and PCB component quality issues) \n\r
Packaging issues: Factory assembly issues, including hardware assembly, packaging, and packaging. (such as factory welding defects, customized factory instructions errors or omissions, packaging missing accessories, packaging material printing errors, etc.) \n\r
Hardware issue: Functional issues caused by hardware design. "(such as abnormal fuel and electricity cut-off function, GPS non positioning, and radio frequency issues) \n\r
Software issues: Functional issues caused by software logic. (such as product standard function issues and customized function issues) \n\r
Platform issues: issues on the web and app sides. "(such as platform functionality issues, API issues, and IOTHUB issues) \n\r
Other issues: Other than the types of customer complaints mentioned above. (such as customer operation issues, installation and wiring issues, and deviations in product function understanding) \n\r 
P.S. If you are unable to confirm the type of issue, you can choose one based on intuition, and FAE will make a secondary correction.`,
  "yujiwanchengshijian": "Expecting Accomplish date",
  "quedingxiugai": "Confirm modifications",
  //Issue Type
  "ptwt": "Platform Issues",
  "ruanjianwenti": "Software Issues",
  "yingjianwenti": "Hardware Issues",
  "lailiaowenti": "Incoming material issues",
  "zubaowenti": "Packaging issues",
  "qitawetni": "Other issues",
  //Models
  "qtlx": "Other models",
  "zdy": "Custom model",
  "shijueanfang": "Visual security products(DVR+ISD)",
  "wuxianchanpin": "Wireless tracker products",
  "youxianchanpin": "Wired tracker products",
  "xuehengka": "Student Card",
  "pingtai": "Platform",
  //Sales Area
  "dianshang": "E-commerce region",
  "dongnanya": "Southeast Asia region",
  "guojika": "International KA region",
  "guopjiduoge": "Multiple international regions",
  "guoneika": "Domestic KA region",
  "guoneiwuxian": "Domestic wireless tracker",
  "guoneiyouxina": "Domestic wired tracker",
  "beimei": "North America region",
  "lamei": "Latin America region",
  "nanya": "South Asia region(India)",
  "ouzhou": "European region",
  "zhongfei": "Middle East and Africa region",
  "dianmo": "Electric motorcycle",
  "wuliuzichan": "Logistics assets",
  "gnqy": "Domestic area",
  //Platform Type
  "tqzx": "Tuqiang Online",
  "tqsx": "Tuqiang Video",
  "fkpt": "Risk control platform",
  "simkpt": "JIMI SIM platform",
  "hdd": "Peace Dingding",
  "aczx": "ICAR Online",
  "fgdzpt": "France Customized platform",
  "qita": "Other ",
  //Progress
  "jindu1": "Start",
  "jindu2": "FAE Site processing",
  "jindu3": "Forward to R&D Site",
  "jindu4": "R&D Site processing",
  "jindu5": "R&D Site accomplished",
  "jindu6": "FAE Site confirm finish",
  "jindu7": "End",


  "clxq": "Processing/Details",
  "gxzt": "Update Status",
  "sjxx": "Date info：",
  "zzsj": "Forward date：",
  "wcsj": "Accomplish date：",
  "stuckPoint": "Current Stuck point",
  "solution": "Issue progress",
  "zygz": "Transfer Site",
  "zygd": "Transfer Work order",
  "cxzy": "Revoke Transfer",
  "xggdzt": "Modify Work Order Status",
  "jibenxinxi": "Basic Info",

  "dianshangqiangxing": "E-commerce Details",
  "deviceStatus": "Device Status",
  "channel": "Purchasing Channels",
  "country": "Country",
  "otherContactInfo": "Other Contact Info",
  "ledStatus": "Device LED Status",
  "simCardOperator": "SIM Operator",
  "usageScenario": "Usage Scenario",
  "installedVehicleModel": "Installed Vehicle Model",

  "kfxiangqing": "Customer service Details",
  "phoneNumber": "Phone Number",
  "title": "Title",
  "reason": "Reason for the Issue",
  "wentichulifangan": "Solution",
  "yanfaxiangqing": "R&D Details",
  "jieshishuoming": "Explanation",
  "fenpeifae": "Assign FAE",
  //progressStatus  tag
  "geren": "Personal",
  "kefu": "Customer service",
  "yanfa": "R&D",
  "xiaochengxu": "Mini Program",
  "dianshang": "E-commerce",
  "quanbu": "All",
  "zijian": "build oneself",
  "yewu": "Salesman",
  "buxian": "All",
  "yihcuangjian": "Created",
  "yiwancheng": "Accomplished",
  "weiwancheng": "Unaccomplished",
  "yizhuanchu": "Transferred",
  "zhuanchuhou": "To be accomplished (R&D Accomplished)",
  "shi": "YES",
  "fou": "NO",
  "disanfang": "Third party",
  "huizhouruanjian": "Huizhou Software R&D",
  "huizhouyingjian": "Huizhou Hardware R&D",
  "jishuzhichi": "FAE",
  "kehu": "Customer",
  "guojikehu": "Business customers",
  "pingtaizhongxin": "Platform",
  "shenzhenruanjian": "Shenzhen Software R&D",
  "shenzhenyingjian": "Shenzhen Hardware R&D",
  "wu": "None",
  "kaiqi": "Active",
  "daipingjia": "To be evaluated",
  "yizhuanchu": "Transferred",
  "yiwancheng": "Accomplished",
  "daiwancheng": "To be accomplished",
  //dialog message
  "fpfaecg": "FAE handler of the work order was modified successfully!",
  "cggychfae": "This work order has been recalled to FAE Site!",
  "xgcg": "Modified successfully!",
  "zhgc": "Transferred successfully!",
  "cgdych": "This work order has been restarted!",
  "cgdywc": "This work order has been Accomplished!",
  "cgdywc": "This work order has been Accomplished!",
  "gxcg": "Update successfully!",
  "hscg": "Recall successfully!",
  "sccg": "Delete successfully!",

  "faexq": "FAE Info",
  "qtjx":"Other models",
  "zidingyi":"Customized models",
  
  "tapdlianjie":"TAPD",
  "zhuangtai":"Attribute",
  "yfkd":"Stuck point",
  "jjfangan":"Solution",

  "xuqiu":"Requirement",
  "yanfa":"R&D",
  
  "chuangjianchenggong":"success",
  "kfgdfwxt":"Customer Work Order System",
  "denglu":"Login",
  "kfgdxt":"Work Order System",
  "gdgl":"Work Order Management",
  "faeAndyw":"FAE / Sales",
  "yhtc":"Log out",
  "mmxg":"Change password",

  "sftc":"Exit or not?",
  "tuichu":"Exit",
  "tixing":"tixing",

  "yuanmima":"Old password",
  "xinmima":"New password",

  "qrkswt":"Is Complaint",

  "complaintFlag":"Is Complaint",

  "tuihuiguanwang":"Return to the official website",

  "zzds":"Go to e-commerce",

  "hykh":"Business Customer",

  "guanwang":"Website",

 "gwzds":"Website to e-commerce",
}
